.navbar {
    display: flex;
    justify-content: space-around;
    background-color: #f7f4fb;
    position: fixed;
    width: 75%;
    margin-top: -113px;
    z-index: 3;
}
.leftnav {
    width: 20%;
    padding: 10px;

}
.rightnav {
    width: 60%;
    display: flex;
    justify-content: space-around;
}
/* a {
    text-decoration: none;
    display: block;
    height: 20px;
    margin-top: 15px;
    padding-bottom: 2px;
} */
a {
    color: #3a384e;
    font-size: 18px;
    font-family: 'Rubik';

}
 a:hover {
    border-bottom: 2px solid greenyellow;
 }
 .logoa {
    /* margin-top: 0px; */
 }
 .logoa:hover {
    border:none
 }
 .logo {
    width: 130px;
    height: 100%;
}
.w3-animate-right {
    border: 1px solid red;
    float: right;
    margin-right: 0px;
    /* margin-left: 100px; */
    /* width: 40px; */
}
.w3-teal {
    display: none;
}

@media only screen and (min-width: 0px) and (max-width:776px) {
    .w3-teal {
        display: block;
        float: right;
    }
    .navbar {
        flex-direction: column;
        width: 100%;
        display: none;
    }
    .leftnav {
        width: 80%;
    }
    .rightnav {
        width: 80%;
        display: none;
        flex-wrap: wrap;
    }

}