.eyeicons{
    display: flex;
    justify-content: space-around;
    row-gap: 25px;
    flex-wrap: wrap;
}
#main {
   margin-top: 109px;
   width: 75%;
}
.top{
    padding-top: -30px;

}
.eyetext {
    margin-top:3%;
    margin-bottom:3%;
    color: #3a384e;
    font-size: 35px;
    font-family: "Philosopher"
}
.grid {
    padding: 2%;
    width:230px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; 
}
.green {
    font-size: 50px;
    font-weight: 500;
    color: #81c342;
    /* line-height: 90px; */
    margin-bottom: -0px;
}
.pt {
    margin-top: -15px;
    font-family: 'Rubik'; font-size: 18px;
}
.lines {
    width: 70%;
    /* border: 2px solid red; */
    margin: auto;
    display: grid;
    grid-template-columns: 250px 250px;
    justify-content: space-around;
    opacity: 5;
    color: white;
    font-family: 'Rubik'; font-size: 18px;
    
}
.top {
    padding: 0px;
    
}
ul {
    justify-content: center;
    text-align: left;
    height: 100%;
    font-family: 'Rubik'; font-size: 18px;
}
.amenities {
    margin-top: 40px;
    padding: 5%;
   background: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.9)), url("https://www.azvenrealty.com/img/villa/3-bhk-villas-in-bangalore-dior.jpg");
   background-repeat: no-repeat;
   background-size: cover; 

   
}
.ameh1 {
    margin-top:5%;
    color: gold;
    margin-bottom:5%;
}
.project {
    background: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.9)), url("http://www.jaypeegreens.com/resourceContent/country-homes-ii-bg_3205.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    /* background-image: url("http://www.jaypeegreens.com/resourceContent/country-homes-ii-bg_3205.jpg");; */
    padding: 5%;
    display: flex;
    justify-content: space-around;
    gap: 10%;

}
.project h1 {
    text-align: left;
    margin-bottom: 20px;
}
.project p {
    font-size: 20px;
    text-align: left;
    background-color: white;
    opacity: 0.2;
    color: black;
    
}
.leftpro {
    padding: 3%;
    width: 45%
}
.leftpro h1 {
    color: gold;
}
.leftpro hr{
    width: 65%;
    height: 3px;
    margin-top: -3px;
    margin-bottom: 23px;
    color: blue;
}
.leftpro p {
    border: 3px solid gray;
    font-family: 'Rubik'; font-size: 18px;
    padding: 5%;
    border-radius: 20px;
    text-align: justify;
}
.rightpro {
    width: 50%;
    padding-top: 11%;
}
.down {
    border: 2px solid #1d1e22;
    border-radius: 10px;
    color: white;
    padding: 5px;
    background-color: #81c342;
    width: 80px;
}
.down:hover {
    background-color: #060e33;
    color: white;
}

iframe {
    width: 100%;
}
.loc {
    background: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.9)), url("https://media.istockphoto.com/vectors/navigation-map-screen-vector-id1139140649?k=20&m=1139140649&s=612x612&w=0&h=wYml_x60Fa5Cy22RFusLhQPPV2dCahyQT4qco8ouLoU=");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 4%;
}

.loc h1 {
    color: gold;
}
.locmap {
    display: flex;
    padding: 3%;
    justify-content: space-around;
}
.adv {
    padding: 3%;
    border: 3px solid gray;
    border-radius: 20px;
    font-family: 'Rubik'; font-size: 18px;
    width: 50%;
    height: 60%;
    /* font-size: 20px; */
    color: white;    
}
.adv {
    animation: slide-up 2s;
}

@keyframes slide-up {
    from {
        opacity: 0.2;
        line-height: 200px;
    }
    to {
        opacity: 1;
        line-height: 20px;
    }
}
.map {
    width: 40%;
    height: 380px;
    /* margin-top: 10%; */
}
.loc h1 {
    margin-bottom: 3%;

}
 a img {
    width: 30px;
 }
 .btm {
    padding: 20px;
    padding-top: 35px;
    /* padding-left: 180px; */
    background-color: #ddd;

 }

 .btm>p {
    margin-top: 40px;
 }
 .btm h4 {
    margin-right: 20px;
 }
 h1 {
    font-family: "Philosopher";
    font-weight: 100;
 }
 .eyediv{
    padding: 3%;
 }
 p {
    /* top:40px;
    left:10px; */
    animation: slideUp ease 1s forwards 1.2s;
  }
  /* .slideUp {
    position: relative;
    opacity: 0.5;
  } */
  @keyframes slideUp {
    0% {transform: translateY(10);}
    100% {transform: translateY(-10px);opacity:1;}
  }

  .footer {
    /* width: 40%; */
    padding: 1%;
    text-align: left;
    background-color: #222;
  }
  .footer h4 {
    color: white;
    text-align: center;
  }
  
  .address h4 {
    width: 100%;
      margin-bottom: 30px;
    }
    .address p {
        /* text-align: left; */
        line-height: 15px;
        flex-wrap: wrap;
    }
    .icns {
        display: flex;
        width: 20%;
        justify-content:space-between;
        margin: auto;
        /* flex-wrap: wrap; */
    }
    .address {
    
    width: 100%;
       margin: auto;
       text-align: center;
       flex-wrap: wrap;
       margin-top: 36px;

       /* color: white; */
    }


.btlast {
  display: flex;
    justify-content: space-around;
    
    padding: 10px;
}
.btlast h4 {
    bottom: 16px;
    margin-bottom: 20px;
}
.induslogo {
    /* width: 100px; */
    height: 40px;
    /* margin-top: -10px; */
}
.last {
    /* justify-content: right; */

    display: flex;
    gap: 5px;
    justify-content: right;
    padding: 10px;
    padding-right: 30px;
}
.last p {
    margin-top: 20px;
}

.wrap {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .button {
    /* min-width: 300px;
    min-height: 60px; */
    font-family: 'Nunito', sans-serif;
    font-size: 22px;
    text-transform: uppercase;
    letter-spacing: 1.3px;
    font-weight: 700;
    color: #313133;
    background: #4FD1C5;
  background: linear-gradient(90deg, rgba(129,230,217,1) 0%, rgba(79,209,197,1) 100%);
    border: none;
    border-radius: 1000px;
    box-shadow: 12px 12px 24px rgba(79,209,197,.64);
    transition: all 0.3s ease-in-out 0s;
    cursor: pointer;
    outline: none;
    position: relative;
    padding: 10px;
    
    }
  
  .button::before {
  content: '';
    border-radius: 1000px;
    min-width: calc(300px + 12px);
    min-height: calc(60px + 12px);
    border: 6px solid #00FFCB;
    box-shadow: 0 0 60px rgba(0,255,203,.64);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all .3s ease-in-out 0s;
  }
  
  .button:hover, .button:focus {
    color: #313133;
    transform: translateY(-6px);
  }
  
  .button:hover::before, .button:focus::before {
    opacity: 1;
  }
  
  .button::after {
    content: '';
    width: 30px; height: 30px;
    border-radius: 100%;
    border: 6px solid #00FFCB;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ring 1.5s infinite;
  }
  
  .button:hover::after, .button:focus::after {
    animation: none;
    display: none;
  }
  
  @keyframes ring {
    0% {
      width: 30px;
      height: 30px;
      opacity: 1;
    }
    100% {
      width: 300px;
      height: 300px;
      opacity: 0;
    }
  }
  .formdiv {
    display: none;
  }
  .btn{
    display: block;
    color: white;
  }
  @media only screen and (min-width: 0px) and (max-width:776px) {
    .formdiv {
        display: block;
        background: #060e33;
        padding: 5%;
        color: white;
        padding-bottom: 40px;
      }

      .formdiv button{
        /* display: block; */
        border: 5px solid #00FFCB;
        margin: auto;
      }

      .formdiv h3 {
        font-weight: 100;
      }

      .formdiv input {
        color: white;
      }
    #main {
        width: 100%;
        margin-top: 44px;
    }
    .eyetext {
        font-size: 25px;
    }
    .grid {
        padding: 2%;
        width:150px;
        /* line-height: 15px; */
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; 
    }
    .adv {
        width: 100%;
        padding: 0px;
        font-size: 15px;
    }
    .btm {
        flex-direction: column;
    }
    .map {
        width: 100%;
        height: 100%;
    }
    .lines {
        /* width: 70%; */
        margin: auto;
        display: grid;
        grid-template-columns: 100%;
        justify-content: space-around;
    }
    .project {
        background-color: #f7f4fb;
        padding: 5%;
        display: flex;
        justify-content: space-around;
        /* gap: 10%; */
        flex-direction: column;
    }
    .project h1 {
        text-align: left;
        margin-bottom: 10px;
    }
    .project p {

        font-size: 20px;
        text-align: left;
    }
    .leftpro {
        padding: 3%;
        width: 100%;

    }
    .leftpro p {
        font-size: 15px;
    }
    .text-card{
        font-size: 15px;

    }
    .pt {
        font-size: 15px;
    }
    .rightpro {
        width: 100%
    }
    iframe {
        width: 100%;
    }
    .loc {
        background-color: #DFE9F2;
        padding: 5%;
        border: 2px solid gray;

    }
    .locmap {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    a img {
        width: 30px;
     }
     .btm {
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10%;
     }
     .rightbtm p{
        line-height: 20px;
     }
     .grid {
        padding: 2%;
        width:150px;
        /* line-height: 15px; */
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; 
    }
    .address h4 {
        font-size: 15px;
    }
    .icns {
        display: flex;
        width: 80%;
        justify-content:space-between;
        margin: auto;
        /* flex-wrap: wrap; */
    }
  }