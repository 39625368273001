.slogo {
    margin-top: 10px;
    width: 100px;
}
.stlogo {
    margin-top: 20px;
}
nav {
    display: none;
    margin-top: 150px;
}

@media only screen and (min-width: 0px) and (max-width:776px){
    nav {
        display: block;
    }
}

